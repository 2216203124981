// import React from 'react';
// // import './PaymentPage.css';
// import '../../asstes/css/payment.css'

import React  from 'react';
import {Link} from 'react-router-dom'
import TripDetails from './TripDetails ';
import PaymentOptions from './PaymentOptions';
import CardDetails from './CardDetails';
import PriceDetails from './PriceDetails';
import '../../asstes/css/payment.css'

const Payment = () => {
  return (


   <>

<div className="sub-banner">
    <div className="container">
      <div className="breadcrumb-area">
        <h1>Payment </h1>
      </div>
      <nav className="breadcrumbs">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">Payment</li>
        </ol>
      </nav>
    </div>
  </div>




    <div className="payment-container">
      <h2>Confirm and Pay</h2>
      <div className="payment-columns">
        <div className="left-column">
          <TripDetails />
          {/* <PaymentOptions /> */}
          <CardDetails />
        </div>
        <div className="right-column">
          <PriceDetails />
        </div>
      </div>
    </div>

    </>
  );
};

export default Payment;

