

import React, { useEffect, useState } from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
import '../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js';
import $ from '../../../node_modules/jquery/dist/jquery.js';
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import DatePicker from 'react-datepicker';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
// import MyComponent from './SearchArea/Mycomponent';

import { Link } from 'react-router-dom';


// const SearchAreaBox = () => {
//   const [checkInDate, setCheckInDate] = useState(null);
//   const [checkOutDate, setCheckOutDate] = useState(null);

//   useEffect(() => {
     
//       $('.selectpicker').selectpicker('refresh');
//   }, []);

//   return (
//       <div className="search-area-box-1">
//           <div className="container">
//               <div className="search-contents">
//                   <form>
//                       <div className="row g-0 search-your-details">
//                           <div className="col-lg-3 col-md-12 align-self-center">
//                               <div className="search-your-rooms">
//                                   <h4>Search</h4>
//                                   <h3 className="room-header">Your <span>Homes</span></h3>
//                               </div>
//                           </div>
//                           <div className="col-lg-9 col-md-12">
//                               <div className="row">
//                                   <div className="col-md-4 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <DatePicker
//                                               selected={checkInDate}
//                                               onChange={date => setCheckInDate(date)}
//                                               className="btn-default"
//                                               placeholderText="Check In"
//                                           />
//                                       </div>
//                                   </div>
//                                   <div className="col-md-4 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <DatePicker
//                                               selected={checkOutDate}
//                                               onChange={date => setCheckOutDate(date)}
//                                               className="btn-default"
//                                               placeholderText="Check Out"
//                                           />
//                                       </div>
//                                   </div>
//                                   <div className="col-md-4 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <select className="selectpicker search-fields" name="adults">
//                                               <option>Adult</option>
//                                               <option>1</option>
//                                               <option>2</option>
//                                               <option>3</option>
//                                               <option>4</option>
//                                               <option>5</option>
//                                           </select>
//                                       </div>
//                                   </div>
//                                   <div className="col-md-4 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <select className="selectpicker search-fields" name="children">
//                                               <option>Child</option>
//                                               <option>1</option>
//                                               <option>2</option>
//                                               <option>3</option>
//                                               <option>4</option>
//                                               <option>5</option>
//                                           </select>
//                                       </div>
//                                   </div>
//                                   <div className="col-md-2 col-sm-4 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <select className="selectpicker search-fields" name="room">
//                                               <option>Room</option>
//                                               <option>3</option>
//                                               <option>4</option>
//                                           </select>
//                                       </div>
//                                   </div>
//                                   <div className="col-md-2 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <select className="selectpicker search-fields" name="pet">
//                                               <option>Pet</option>
//                                               <option>Yes</option>
//                                               <option>No</option>
//                                           </select>
//                                       </div>
//                                   </div>
//                                   <div className="col-md-4 col-sm-6 col-12 col-pad-0">
//                                       <div className="form-group">
//                                           <button className="btn-md btn-theme btn-4 btn-7 w-100">Search</button>
//                                       </div>
//                                   </div>


                         
//                               </div>
//                           </div>
//                       </div>
//                   </form>
//               </div>
//           </div>
//       </div>
//   );
// };

// export default SearchAreaBox;

const SearchAreaBox = () => {
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [pet, setPet] = useState('No');

  useEffect(() => {
    $('.selectpicker').selectpicker('refresh');
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Check-In Date:', checkInDate);
    console.log('Check-Out Date:', checkOutDate);
    console.log('Adults:', adults);
    console.log('Children:', children);
    console.log('Pet:', pet);
  };

  return (
    <div className="search-area-box-1">
      <div className="container">

        <div className='main-title'>

          <h1>Search
          Your Homes</h1>

        </div>
        <div className="search-contents">

       
        <form >
                <div className="search-your-details">
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                      <input type="date" className="btn-default datepicker" placeholder="Check In" />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <input type="date" className="btn-default datepicker" placeholder="Check Out" />
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <select className="form-control search-fields" name="room">
                          <option>Room</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <select className="form-control search-fields" name="beds">
                          <option>Beds</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                      </div>
                    </div> */}
                                               <div className="col-md-4 col-sm-6">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="adults">
                                                          <option>Adult</option>
                                                          <option>1</option>
                                                          <option>2</option>
                                                          <option>3</option>
                                                          <option>4</option>
                                                          <option>5</option>
                                                      </select>
                                                  </div>
                                              </div> 
                                              <div className="col-md-4 col-sm-6">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="children">
                                                          <option>Child</option>
                                                          <option>1</option>
                                                          <option>2</option>
                                                          <option>3</option>
                                                          <option>4</option>
                                                          <option>5</option>
                                                      </select>
                                                  </div>
                                              </div>
  
                                              <div className="col-md-4 col-sm-12">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="Pet">
                                                          <option>Pet</option>
  
                                                          <option>Yes</option>
                                                          <option>No</option>
  
                                                      </select>
                                                  </div>
                                              </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group mb-0">
                        <Link type="submit" className="btn-md btn-theme btn-4 btn-7 w-100" to="/payment">Book Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

          {/* <form onSubmit={handleSubmit}>
            <div className="row g-0 search-your-details">
              <div className="col-lg-3 col-md-12 align-self-center">
                <div className="search-your-rooms">
                  <h4>Search</h4>
                  <h3 className="room-header">
                    Your <span>Homes</span>
                  </h3>
                </div>
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <DatePicker
                        selected={checkInDate}
                        onChange={(date) => setCheckInDate(date)}
                        className="btn-default"
                        placeholderText="Check In"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <DatePicker
                        selected={checkOutDate}
                        onChange={(date) => setCheckOutDate(date)}
                        className="btn-default"
                        placeholderText="Check Out"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <select
                        className="selectpicker search-fields"
                        name="adults"
                        value={adults}
                        onChange={(e) => setAdults(e.target.value)}
                      >
                        <option value="1">1 Adult</option>
                        <option value="2">2 Adults</option>
                        <option value="3">3 Adults</option>
                        <option value="4">4 Adults</option>
                        <option value="5">5 Adults</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <select
                        className="selectpicker search-fields"
                        name="children"
                        value={children}
                        onChange={(e) => setChildren(e.target.value)}
                      >
                        <option value="0">0 Children</option>
                        <option value="1">1 Child</option>
                        <option value="2">2 Children</option>
                        <option value="3">3 Children</option>
                        <option value="4">4 Children</option>
                        <option value="5">5 Children</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <select
                        className="selectpicker search-fields"
                        name="pet"
                        value={pet}
                        onChange={(e) => setPet(e.target.value)}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 col-pad-0">
                    <div className="form-group">
                      <button className="btn-md btn-theme btn-4 btn-7 w-100" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form> */}
        </div>
      </div>
    </div>
  );
};

export default SearchAreaBox;

