import React from 'react';
// import payWithImg from './paywith.png';

const CardDetails = () => {
  return (
    <div className="pay-with">
      <h2>Pay with</h2>
      {/* <img src={payWithImg} alt="Pay with options" className="payment-icons"/> */}
      <div className="payment-form">
        <div className="form-group">
          <label htmlFor="card-number">Card number</label>
          <input type="text" id="card-number" placeholder="Card number" />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="expiration">Expiration</label>
            <input type="text" id="expiration" placeholder="MM/YY" />
          </div>
          <div className="form-group">
            <label htmlFor="cvv">CVV</label>
            <input type="text" id="cvv" placeholder="CVV" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="postcode">Postcode</label>
          <input type="text" id="postcode" placeholder="Postcode" />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country/region</label>
          <select id="country">
            <option value="">Select country/region</option>
            <option value="us">United States</option>
            <option value="ca">Canada</option>
            {/* Add more countries as needed */}
          </select>
        </div>

        <div className="form-group">
         <h4>Cancellation policy </h4>

         <p> Free cancellation before 7 Jul. Cancel before check-in on 8 Jul for a partial refund.<a> Learn more</a> </p>
        </div>

        <div className="form-group">
         <button className='btn btn btn-danger'> Confirm and Pay</button>
        </div>




      </div>
    </div>
  );
};

export default CardDetails;
