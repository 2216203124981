import React, { useState } from 'react';
import CheckAvailability from './CheckAvailability';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('home');

    return (
        <div className='content-area-15 rooms-detail-section'>

            <div className='container'>

                <div className='row'>
                    <div className='col-md-8'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button 
                        className={`nav-link ${activeTab === 'home' ? 'active' : ''}`} 
                        id="home-tab" 
                        data-bs-toggle="tab"
                        type="button" 
                        role="tab" 
                        aria-controls="home" 
                        aria-selected={activeTab === 'home'}
                        onClick={() => setActiveTab('home')}
                    >
                        Over View
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button 
                        className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} 
                        id="profile-tab" 
                        data-bs-toggle="tab"
                        type="button" 
                        role="tab" 
                        aria-controls="profile" 
                        aria-selected={activeTab === 'profile'}
                        onClick={() => setActiveTab('profile')}
                    >
                        Amenities
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button 
                        className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`} 
                        id="contact-tab" 
                        data-bs-toggle="tab"
                        type="button" 
                        role="tab" 
                        aria-controls="contact" 
                        aria-selected={activeTab === 'contact'}
                        onClick={() => setActiveTab('contact')}
                    >
                        House Rules
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button 
                        className={`nav-link ${activeTab === 'map' ? 'active' : ''}`} 
                        id="map-tab" 
                        data-bs-toggle="tab"
                        type="button" 
                        role="tab" 
                        aria-controls="map" 
                        aria-selected={activeTab === 'map'}
                        onClick={() => setActiveTab('map')}
                    >
                        Map
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button 
                        className={`nav-link ${activeTab === 'reviews' ? 'active' : ''}`} 
                        id="reviews-tab" 
                        data-bs-toggle="tab"
                        type="button" 
                        role="tab" 
                        aria-controls="reviews" 
                        aria-selected={activeTab === 'reviews'}
                        onClick={() => setActiveTab('reviews')}
                    >
                        Reviews
                    </button>
                </li>
            </ul>

            <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${activeTab === 'home' ? 'show active' : ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="rooms-description mb-50">
  
  
  <h3> Unit 1, 3 Bedroom Home Crestwynd  </h3>

  <ul class="fecilities clearfix">
      <li>
           <i class="fa fa-users"></i> &nbsp;
            8 Maximum Guest 
           
      </li>

      <li>
          <i class="flaticon-bed"></i> &nbsp;
           3 Bed Rooms 
          
     </li>

   
      <li>
          <i class="fa fa-bath" aria-hidden="true"></i> &nbsp;
           3 Baths
      </li>
     
  </ul>



  {/* <!-- paragraph --> */}

  <ul class="room_details">
      <li>Bedroom 1: 1 King Bed</li>
      <li> Bedroom 2: 1 Queen Bed</li>
      <li> Bedroom 3: 2 Single Bed</li>
      <li> One bedroom is downstairs and two are upstairs</li>
      

  </ul>

  <br/>

  <p>
      Unit 1 is a nice three-bedroom home personally decorated,
      furnished, and maintained well.
      It is located in Crestwynd Bay a gated community that is
      peaceful and safe as well as closest to shopping, Disney, and
      restaurants.
      Fully furnished.
  </p>

  <p> It is located in Crestwynd Bay a gated community that is peaceful and safe as well as closest to shopping, Disney,
      and restaurants.
      The home consists of 1 KING bed with an attached bathroom, One bedroom is downstairs and two are upstairs.
      1 queen bedroom with attached bath,
      1 Kids bedroom with 2 twins and Living Room with a sleeper sofa.
      The home can sleep 6 people very comfortably and 8 people with the use of a sleeper sofa.
      The home has in-home laundry (washer-dryer). Very close to the pool.
      Cable and flat-screen TVs are included in all rooms. WIFI and High-Speed internet are included free of charge
      (though subject to cable company's operations).
      The kitchen includes all normal "home" appliances - microwave, refrigerator, cooking range, dishwasher, toaster,
      coffee maker, blender.
      An initial packet of coffee and sugar, as well as a small hotel soap bar, shampoo, and conditioner, is provided- you
      will have to get more from Walmart, Publix, or other stores nearby for longer stays or for a bigger group.
      Please note: you will be required to sign a rental agreement so that we ensure you have read the rules of the HOA
      and the city. Local City laws require us to keep track of all visitors.
  </p>

  <p>
  
      Nice well maintained newer community - just 5 minutes to the Disney
      parks.
      Each home is 3 Bedroom, 3 baths and fully furnished (Crestwynd Bay)-
      visit - MyOrlandoStay near Disney.
      This is an ideal place to spend a luxury vacation. It is located in a
      Gated Community providing safety. In front of the community pool- quiet
      community for a relaxing time after visiting Orlando attractions. Very
      well located- up to 8 people can stay easily. Initial Bath toiletries
      and Coffee kit provided.
      Please note: you will be required to sign a rental agreement so that we ensure you have read the rules of the HOA
      and the city. Local City laws require us to keep track of all visitors.
  
  
  
  </p>

</div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                   {/* <!-- Amenities start --> */}
                   <div class="amenities">
                                                    <h3>Amenities</h3>
                                                    <div class="row">
                                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                                            <ul class="condition">
                                                                <li>
                                                                    <i class="flaticon-air-conditioning"></i>Air
                                                                    conditioning
                                                                </li>
                                                                <li>
                                                                    <i class="flaticon-balcony-and-door"></i>Balcony
                                                                </li>
                                                                <li>
                                                                    <i class="flaticon-weightlifting"></i>Swimming Pool
                                                                </li>
                                                                <li>
                                                                    <i class="flaticon-parking"></i>Parking
                                                                </li>
                                                                {/* <!-- <li>
                                                                    <i class="flaticon-sunbed"></i>Beach View
                                                                </li> --> */}
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                                            <ul class="condition">
                                                                <li>
                                                                    <i class="flaticon-bed"></i>3 Bedroom
                                                                </li>
                                                                
                                                              
                                                                <li>
                                                                    <i class="flaticon-graph-line-screen"></i>TV
                                                                </li>

                                                                <li>
                                                                    <i class="flaticon-phone-receiver"></i>Satellite / Cable
                                                                </li> 
                                                                
                                                                
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                                            <ul class="condition">
                                                                
                                                                <li>
                                                                    <i class="flaticon-bed"></i>3 Bathrooms
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        class="flaticon-wifi-connection-signal-symbol"></i>Free
                                                                    Wi-Fi
                                                                </li>
                                                                <li>
                                                                    <i class="flaticon-no-smoking-sign"></i>No Smoking
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Amenities end --> */}
                </div>
                <div className={`tab-pane fade ${activeTab === 'contact' ? 'show active' : ''}`} id="contact" role="tabpanel" aria-labelledby="contact-tab">
                   {/* <!-- House reles start --> */}
                   <div class="house-rules">
                                                    <h3>House Rules</h3>
                                                    <ul>
                                                        <li><i class="fa fa-hand-o-right"></i> Check-in: 5:00 PM
                                                            </li>
                                                        <li><i class="fa fa-hand-o-right"></i> Checkout: 10:00 AM</li>
                                                        <li><i class="fa fa-hand-o-right"></i> No smoking</li>
                                                        <li><i class="fa fa-hand-o-right"></i> Pet Friendly (2 Pets)</li>
                                                        <li><i class="fa fa-hand-o-right"></i> No parties or events</li>
                                                        <li><i class="fa fa-hand-o-right"></i> Minimum booking age required 21 Years </li>


                                                    </ul>
                                                </div>
                                                {/* <!-- House reles end --> */}
                </div>
                <div className={`tab-pane fade ${activeTab === 'map' ? 'show active' : ''}`} id="map" role="tabpanel" aria-labelledby="map-tab">
                    {/* <!-- Pricing plans start --> */}
                    <div class="pricing-table">
                                                    <h3>Location</h3>

                                                    <p>
                                                        The unit address is
                                                        <b> 2634 Andros Ln,
                                                            Kissimmee, FL 34747.</b>
                                                        The community name is Crestwynd Bay (the main entrance is on Old Lake Wilson Road right next to the Westgate
                                                        Timeshare Resorts and after Walmart).
                                                        Directions from Orlando Airport (MCO):
                                                        <a href="https://goo.gl/maps/kUXAQgVub6eXdgWu7">Google Directions</a>
                                                    </p>
                                                    <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.1829942845143!2d-81.59456062591958!3d28.32306329806927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd7eb2866f535d%3A0x97a495aa8bebad6!2s2634%20Andros%20Ln%2C%20Kissimmee%2C%20FL%2034747%2C%20USA!5e0!3m2!1sen!2sin!4v1711084875583!5m2!1sen!2sin"
                                                        width="100%" height="300" style={{border:'0'}}></iframe>
                                                </div>
                                                {/* <!-- Pricing plans end --> */}
                </div>
                <div className={`tab-pane fade ${activeTab === 'reviews' ? 'show active' : ''}`} id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                <div class="cancellation">
                                                    {/* <!-- Comments section start --> */}
                                                    <div class="comments-section">
                                                        {/* <!-- Main Title 2 --> */}
                                                        <div class="main-title-2">
                                                            <h1>Room Reviews</h1>
                                                        </div>

                                                        <ul class="comments mb-50">
                                                            <li>
                                                                <div class="comment">

                                                                    <div class="comment-content">
                                                                        <div class="comment-meta">
                                                                            <div class="comment-meta-author">
                                                                                Catherine
                                                                            </div>

                                                                            {/* <!-- <div class="comment-meta-date">
                                                <span>8:42 PM 3/3/2020</span>
                                            </div> --> */}
                                                                        </div>
                                                                        <div class="clearfix"></div>
                                                                        <div class="comment-body">
                                                                            <div class="comment-rating">
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>
                                                                                <i class="fa fa-star"></i>

                                                                            </div>
                                                                            <p>I think everything was great! Home,
                                                                                furniture, location, amenities were all
                                                                                good andas described... I only missed
                                                                                having hand towels for the bathrooms and
                                                                                a bath towel mat. Thank you for
                                                                                an awesome stay.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul>
                                                                    <li>
                                                                        <div class="comment">

                                                                            <div class="comment-content">
                                                                                <div class="comment-meta">
                                                                                    <div class="comment-meta-author">
                                                                                        Juan Carlos Perez
                                                                                    </div>


                                                                                </div>
                                                                                <div class="clearfix"></div>
                                                                                <div class="comment-body">
                                                                                    <div class="comment-rating">
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>

                                                                                    </div>
                                                                                    <p>Lorem ipsum dolor sit amet,
                                                                                        conser adipiscing elit. Donec
                                                                                        luctus tincidunt aliquam.
                                                                                        Aliquam gravida massa at sem.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <div class="comment">

                                                                            <div class="comment-content">
                                                                                <div class="comment-meta">
                                                                                    <div class="comment-meta-author">
                                                                                        Barbara Latterell
                                                                                    </div>
                                                                                </div>
                                                                                <div class="clearfix"></div>
                                                                                <div class="comment-body">
                                                                                    <div class="comment-rating">
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>
                                                                                        <i class="fa fa-star"></i>

                                                                                    </div>
                                                                                    <p>We had a great time at Manny’s
                                                                                        place and it was perfect for our
                                                                                        needs. Lots of space and great
                                                                                        beds. Great location.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <!-- Comments section end --> */}



                                                </div>
                </div>
            </div>
                    </div>

                   <CheckAvailability/>

                </div>

            </div>
            
        </div>
    );
};

export default Tabs;
