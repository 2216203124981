import React from 'react'
import { Link } from 'react-router-dom'


const Blog = () => {
  return (
    <>
       <div className="blog-section content-area comon-slick">
    <div className="container">
       
        <div className="main-title">
            <h1>Our Blog</h1>
           
        </div>
<div className="row">
     
        <div className="item slide-box col-md-4" >
          <div className="blog-1">
            <div className="blog-image">
              <img src="https://myorlandostay.com/img/Units/Unit1/Small/24_Small.JPG" alt="image" className="img-fluid w-100" />
              {/* <div className="profile-user">
                <img src="img/avatar/avatar-1.jpg" alt="user" />
              </div> */}
              <div className="date-box">
                <span>09</span>Sep
              </div>
            </div>
            <div className="detail">
              {/* <div className="post-meta clearfix">
                <ul>
                  <li>
                    <strong>By: Auro Navanth</strong>
                  </li>
                  <li className="float-right mr-0">
                    <Link href="#" tabIndex="-1"><i className="fa fa-commenting-o"></i></Link>205
                  </li>
                  <li className="float-right">
                    <Link href="#" tabIndex="-1"><i className="fa fa-calendar"></i></Link>328
                  </li>
                </ul>
              </div> */}
              <h3>
                <Link href="/blog-details">
                A Guide to Planning the Perfect Family Vacation in Orlando
                </Link>
              </h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
          </div>
        </div>



        <div className="item slide-box col-md-4" >
          <div className="blog-1">
            <div className="blog-image">
              <img src="https://myorlandostay.com/img/Units/Unit2/Small/IMG_0047.jpg" alt="image" className="img-fluid w-100" />
              {/* <div className="profile-user">
                <img src="img/avatar/avatar-1.jpg" alt="user" />
              </div> */}
              <div className="date-box">
                <span>09</span>Sep
              </div>
            </div>
            <div className="detail">
              {/* <div className="post-meta clearfix">
                <ul>
                  <li>
                    <strong>By: Auro Navanth</strong>
                  </li>
                  <li className="float-right mr-0">
                    <Link href="#" tabIndex="-1"><i className="fa fa-commenting-o"></i></Link>205
                  </li>
                  <li className="float-right">
                    <Link href="#" tabIndex="-1"><i className="fa fa-calendar"></i></Link>328
                  </li>
                </ul>
              </div> */}
              <h3>
                <Link href="/blog-details">
                Why MyOrlandoStay Vacation Rentals Are Perfect for Extended Stays
                </Link>
              </h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
          </div>
        </div>


        <div className="item slide-box col-md-4" >
          <div className="blog-1">
            <div className="blog-image">
              <img src="https://myorlandostay.com/img/Units/Unit4/Small/01-Web.jpg" alt="image" className="img-fluid w-100" />
              {/* <div className="profile-user">
                <img src="img/avatar/avatar-1.jpg" alt="user" />
              </div> */}
              <div className="date-box">
                <span>09</span>Sep
              </div>
            </div>
            <div className="detail">
              {/* <div className="post-meta clearfix">
                <ul>
                  <li>
                    <strong>By: Auro Navanth</strong>
                  </li>
                  <li className="float-right mr-0">
                    <Link href="#" tabIndex="-1"><i className="fa fa-commenting-o"></i></Link>205
                  </li>
                  <li className="float-right">
                    <Link href="#" tabIndex="-1"><i className="fa fa-calendar"></i></Link>328
                  </li>
                </ul>
              </div> */}
              <h3>
                <Link href="/blog-details">
                Exploring Orlando on a Budget: Tips and Tricks
                </Link>
              </h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>

    </>
  )
}

export default Blog
