import React from 'react';

const TripDetails = () => {
  return (
    <div className="trip-details">
      <h3>Your Trip</h3>
      <div className="trip-item">
        <div className="detail-title">Dates</div>
        <div>8–12 Jul</div>
        <button className="edit-button">Edit</button>
      </div>
      <div className="trip-item">
        <div className="detail-title">Guests</div>
        <div>1 guest, 1 pet</div>
        <button className="edit-button">Edit</button>
      </div>
    </div>
  );
};

export default TripDetails;
