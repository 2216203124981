import React from 'react';

const PriceDetails = () => {
  return (
    <div className="price-details">
      <img 
        src="https://disneydreamrentals.com/img/units/unit6/1.jpg" 
        alt="Townhouse" 
        className="price-image" 
        style={{width:'100px', height:'100px'}}
      />
      <div className="price-summary">
        <h4>Disney Nice Townhouse 3 bedroom 3 bath- Unit 6</h4>
        <p>Entire townhouse</p>
        <span>★ 4.57 (77 reviews)</span>
        <div className="price-breakdown">
          <div className="price-item"><span>$112.5 x 4 nights</span><span>$450</span></div>
          <div className="price-item"><span>Special offer</span><span>-$33</span></div>
          <div className="price-item"><span>Cleaning fee</span><span>$100</span></div>
          <div className="price-item"><span>Airbnb service fee</span><span>$72.99</span></div>
          <div className="price-item"><span>Taxes</span><span>$69.8</span></div>
          <div className="total"><span>Total (USD)</span><span>$659.79</span></div>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;