import React, { useEffect } from 'react';
import styles from './FullScreenPopup.module.css';

const FullScreenPopup = ({ onClose }) => {

    useEffect(() => {
        const handleClickOutside = (event) => {
          const popupElement = document.querySelector(`.${styles.popup}`);
          if (popupElement && !popupElement.contains(event.target)) {
            onClose();
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        
        document.body.style.overflow = 'hidden';
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
         
          document.body.style.overflow = 'auto';
        };
      }, [onClose, styles.popup]); 
      

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       const popupElement = document.querySelector(`.${styles.popup}`);
//       if (popupElement && !popupElement.contains(event.target)) {
//         onClose();
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//       document.body.style.overflow = 'hidden'; // Ensure scrollbar is shown when modal is unmounted
//     };
//   }, [onClose]);


  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <button onClick={onClose} className={styles.closeButton}>
          <i className='fa fa-times'></i> Close
        </button>
        {/* Add your popup content here */}
        <div class="container">
        {/* <!-- Main title --> */}
        <div class="main-title">
            <h1>Unit 1 Photo tour</h1>
            
        </div>
        
        <div class="row filter-portfolio">
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/29_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                      {/* <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Unit 1</h3>
                        </div>
                    </figcaption>  */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/11_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                {/* <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Double Room</h3>
                        </div>
                    </figcaption>  */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/05_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Single Room</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item">
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/09_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Family Room</h3>
                        </div>
                    </figcaption>
                </figure> --> */}
            </figure></div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/11_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Standard</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/12_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Couple Room</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/14_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Family Room</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item">
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/15_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Standard</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 filtr-item" >
                <figure class="portofolio-thumb">
                    <a href="#"><img src="https://myorlandostay.com/img/Units/Unit1/Small/16_Small.JPG" alt="image" class="img-fluid w-100"/></a>
                    {/* <!-- <figcaption>
                        <div class="figure-content">
                            <h3 class="title">Couple Room</h3>
                        </div>
                    </figcaption> --> */}
                </figure>
            </div>
        </div>
    </div>
      </div>
    </div>
  );
};

export default FullScreenPopup;
