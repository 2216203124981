import React from 'react'
import About_img from '../../asstes/img/about/img-6.jpg'
import { Link } from 'react-router-dom'
import Facilites from '../Facilites/Facilites'
const About = () => {
  return (
    <>


<div className="sub-banner">
        <div className="container">
          <div className="breadcrumb-area">
            <h1>About Us</h1>
          </div>
          <nav className="breadcrumbs">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">About Us</li>
            </ol>
          </nav>
        </div>
      </div>

     <div className="about-hotel-MyOrlandoStay-2 content-area-6 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft delay-04s align-self-center">
              <div className="text">
                <h5>Welcome To <span>MyOrlando Stay</span></h5>
                <h1>Finest Luxury Room </h1>
                <p className="mb-4">Situated in Orlando, Disney Area Vacation Rental by MyOrlandoStay offers multiple townhomes with a year-round outdoor heated pool. Disney's Hollywood Studios, Magic Kingdom, Animal Kingdom and Epcot are 1 to 1.5 miles away. Universal Parks are 7-8 miles (less than 10 minutes) with SeaWorld and Aquatica even closer Free WiFi is available throughout the property and free private parking is available on site.</p>
                <p className="mb-30">All units are air conditioned and include a seating and dining area. A flat-screen TV with cable channels and DVD player are available in some units. There is also a kitchen, equipped with a dishwasher, oven and microwave. A toaster, a refrigerator and stove top are also offered, as well as a coffee machine and a kettle. Bed linen is featured. Our rentals also include a children's playground. Bike rental and car rental are available at the property and the area is also popular for golfing.</p>
                <a className="btn-1 comon-btn" href="#"><span>Read More</span></a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight delay-04s">
              <div className="hotel-banner">
                {/* <img src="/img/about/img-6.jpg"  /> */}

                <img src={About_img} alt="photo" className="img-fluid w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Facilites/>
      
    </>
  )
}

export default About
