import React from 'react'

const Facilites = () => {
  return (
    <>
     <div className="our-facilities-section-2">
            <div className="container">
                <div className="main-title">
                    <h1>Our Facilities</h1>
                </div>
                <div className="row wow fadeInUp delay-04s" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">1</div>
                            <div className="icon">
                                <i className="flaticon-school-call-phone-reception"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">24-hour Reception</a>
                                </h3>
                                <p>Enjoy the convenience of round-the-clock assistance with our 24-hour reception service at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">2</div>
                            <div className="icon">
                                <i className="fa fa-paw" aria-hidden="true"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Pet Friendly</a>
                                </h3>
                                <p>Bring your furry friends along! We're pet-friendly at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">3</div>
                            <div className="icon">
                                <i className="flaticon-graph-line-screen"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Flat Screen TV</a>
                                </h3>
                                <p>Enjoy entertainment at your fingertips with flat-screen TVs in every room at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">4</div>
                            <div className="icon">
                                <i className="flaticon-swimming-silhouette" aria-hidden="true"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Swimming Pool</a>
                                </h3>
                                <p>Dive into relaxation with our refreshing swimming pool at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">5</div>
                            <div className="icon">
                                <i className="flaticon-parking"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Free Parking</a>
                                </h3>
                                <p>Park worry-free with complimentary parking available for all guests at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">6</div>
                            <div className="icon">
                                <i className="flaticon-wifi-connection-signal-symbol"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Free Wi-Fi</a>
                                </h3>
                                <p>Stay connected with complimentary Wi-Fi available throughout your stay at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">7</div>
                            <div className="icon">
                                <i className="flaticon-wifi-connection-signal-symbol"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Gated Community</a>
                                </h3>
                                <p>Enjoy security and privacy in our gated community at MyOrlando Stay.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">8</div>
                            <div className="icon">
                                <i className="flaticon-wifi-connection-signal-symbol"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">2 Miles from Disney</a>
                                </h3>
                                <p>Just 2 miles from Disney, MyOrlando Stay offers easy theme park access.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="services-box">
                            <div className="number">8</div>
                            <div className="icon">
                                <i className="flaticon-wifi-connection-signal-symbol"></i>
                            </div>
                            <div className="detail">
                                <h3>
                                    <a href="#">Walmart Supercenter & Fuel Station: 1 mile away!</a>
                                </h3>
                                <p>Only 1 mile from MyOrlando Stay, making it convenient for all your needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </>
  )
}

export default Facilites
