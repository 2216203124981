import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Img_unit1 from '../../asstes/img/units/unit1/1.jpg';

const PropertyTiles = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get('https://ykbaseapi.yugalkunj.org/ykrestapi/PaymentChannel/GetLodgixData?apiurl=%2Fpublic-api%2Fv2%2Fproperties%2F');
        const properties = response.data.results;
        console.log('Properties:', properties);

        const propertyPromises = properties.map(async property => {
          try {
            const photoResponse = await axios.get(`https://ykbaseapi.yugalkunj.org/ykrestapi/PaymentChannel/GetLodgixData?apiurl=%2Fpublic-api%2Fv2%2Fproperties%2F${property.id}%2Fphotos%2F`);
            console.log(`Images for Property ${property.id}:`, photoResponse.data.results);
            
            // Print each image URL with the property ID
            photoResponse.data.results.forEach(photo => {
              console.log(`Property ID: ${property.id}, Image URL: ${photo.url}`);
            });

            return { ...property, photos: photoResponse.data.results || [] };
          } catch (photoError) {
            console.error(`Error fetching photos for property ${property.id}:`, photoError);
            return { ...property, photos: [] };
          }
        });

        const results = await Promise.all(propertyPromises);
        console.log('Final Properties with Photos:', results);
        setProperties(results);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property details:', error);
        setError('An error occurred while fetching property details.');
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="content-area hotel-section bg-grey">
        <div className="overlay">
          <div className="container">
            <div className="main-title">
              <h1>Our Properties</h1>
            </div>
            <div className="row wow fadeInUp delay-04s" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
              {properties.map((property) => (
                <div key={property.id} className="col-lg-4 col-md-6 col-sm-12">
                  <div className="hotel-box">
                    <div className="photo-thumbnail">
                      <div className="photo">
                        {property.photos.length > 0 && property.photos[0].url ? (
                          <img
                            src={property.photos[0].url}
                            alt="photo"
                            className="img-fluid w-100"
                            onError={(e) => {
                              console.error(`Error loading image from ${e.target.src}. Falling back to default image.`);
                              e.target.onerror = null;
                              e.target.src = Img_unit1;
                            }}
                          />
                        ) : (
                          <img src={Img_unit1} alt="default photo" className="img-fluid w-100" />
                        )}
                        <Link to={`/property/${property.id}`}>
                          <span className="blog-one__plus"></span>
                        </Link>
                      </div>
                      <div className="pr">
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        Available Room
                      </div>
                    </div>
                    <div className="detail clearfix">
                      <h3>
                        <Link to={`/property/${property.id}`}>{property.name}</Link>
                      </h3>
                      <div className="fecilities">
                        <ul className="facility-list">
                          <li>
                            <i className="flaticon-bed"></i> {property.bedrooms} Bedrooms
                          </li>
                          <li>
                            <i className="fa fa-users"></i> {property.max_guests} Guests
                          </li>
                          <li>
                            <i className="fa fa-bath" aria-hidden="true"></i> {property.bathrooms} Bathrooms
                          </li>
                        </ul>
                        <p>
                          <Link className="btn-1 comon-btn bnt-none-992" to={`/property/${property.id}`}><span>Book Now</span></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyTiles;
