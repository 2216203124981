



import About from './Components/About/About';
import Index from './Components/About/Index';
import Slider from './Components/Banner/Slider';
import Blog from './Components/Blog/Blog';
import Facilites from './Components/Facilites/Facilites';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Searchbox from './Components/SearchArea/Searchbox';
import PropertyTiles from './Components/Units/PropertyTiles';
import Properties1 from './Components/Units/Properties1'

import Teams from './Components/Team/Teams';
import CheckAvailability from './Components/Units/CheckAvailability';
 //import Rooms from './Components/Units/Rooms';
//  import Properties from './Components/Units/Properties.js'


function App() {
  return (
    <>
 <Slider/>
 <Index/>

 <Searchbox/>
 {/* <Rooms/> */}

 <PropertyTiles/>
  {/* <Properties/>   */}
 {/* <Rooms/>  */}
 <Facilites/>
 <Blog/>
 <Teams/>


    </>
  );
}

export default App;
