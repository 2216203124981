import React from 'react'
import RoomDetails from './RoomDetails'
import {Link} from 'react-router-dom'
import ImageSlider from './ImageSlider'
 import Tabs from './Tabs '


const Unit1 = () => {
  return (
    <>

<div class="sub-banner">
        <div class="container">
            <div class="breadcrumb-area">
                <h1>Unit 1, 3 Bedroom Home Crestwynd </h1>
            </div>
            <nav class="breadcrumbs">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item active">Unit 1, 3 Bedroom Home Crestwynd</li>
                </ol>
            </nav>
        </div>
</div>
    <ImageSlider/>
   
     <Tabs/> 
      
    </>
  )
}

export default Unit1
