import React from 'react'
import manny from '../../asstes/img/staff/img-1.jpg'
import Trevor from '../../asstes/img/staff/img-2.jpg'
import Richa from '../../asstes/img/staff/img-3.jpg'
import Rajiv from '../../asstes/img/staff/img-4.jpg'

import Annie from '../../asstes/img/staff/img-5.jpg'







const Teams = () => {
  return (
    <>
     <div className="content-area staff-section" style={{marginBottom:'80px'}}>
            <div className="container">
                <div className="main-title">
                    <h1>Our Teams</h1>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src={manny} alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Manny</a>
                                </h4>
                                <p>Involved in IT for over 20 years and Vacation rental management for the last 5 years M.S in Engineering, MBA.</p>
                                {/* <p><Link href="tel:+55-417-634-7071">+1 204 777 0187</Link></p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src={Trevor} alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Trevor</a>
                                </h4>
                                <p>Manages properties, involved in construction, works with Disney and other parks</p>
                            </div>
                        </div>
                    </div>
                    {/* Add more team members as necessary */}

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src={Richa} alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Richa</a>
                                </h4>
                                <p>Involved in IT and manages vacation rentals and back office</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src={Rajiv} alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Rajiv</a>
                                </h4>
                                <p>Working with the Travel Industry for over 6 years. Working with MyOrlandoStay from 2012 January. Specializes in Sales & Marketing.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src={Annie} alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Annie</a>
                                </h4>
                                <p>Working with the Travel Industry for over 3 years. Working with MyOrlandoStay from 2021 January. Specializes in Sales & Marketing.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="staff-box-1">
                            <div className="photo">
                                <img src="https://stssevastorage.blob.core.windows.net/myorlandostay/teams/Jenny.jpg" alt="staff" className="img-fluid w-100" />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <a href="#">Jenny</a>
                                </h4>
                                <p>An avid traveler herself, Jenny has an overall 15+ years of experience spanning diverse domain & sectors including but not limited to pharmaceutical, IT, insurance & dotcom. Dedicated and committed to make MyOrlandoStay as number one, she is not hesitant to say that some of the pathbreaking ideas come through her.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
      
    </>
  )
}

export default Teams
