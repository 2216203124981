"use client"
// ImageSlider.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import FullScreenPopup  from './FullScreenPopup'

const ImageSlider = () => {
 
  // const [showPopup, setShowPopup] = useState(false);

 

  // const togglePopup = () => {
  //   setShowPopup(true);
  //   alert("body scroll bar hidden ")
  //   console.log("body scroll bar hidden")
  //   document.body.style.overflow = 'hidden'; // Hide body scrollbar
  // };

  // const closePopup = () => {
  //   setShowPopup(false);
  //   alert("body scroll bar auto");
  //   console.log("body scroll bar auto")
  //   document.body.style.overflow = 'auto'; // Show body scrollbar
  // };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(true);
   
    document.body.style.overflow = 'hidden !important'; 
  };

  const closePopup = () => {
    setShowPopup(false);
    
    document.body.style.overflow = 'auto'; 
  };


  const [activeSlide, setActiveSlide] = useState(0); // State to track active slide

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (index) => setActiveSlide(index) // Update active slide index
  };


  const handleSlideChange = () => {
    const images = document.querySelectorAll('.carousel-item img');
    images.forEach((img) => img.classList.remove('active'));
    images[activeSlide].classList.add('active');
  };


  return (
    <div className="banner" id="banner-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="hotels-detail-slider simple-slider">
              <Slider {...settings} beforeChange={handleSlideChange}>
                <div className={`carousel-item ${activeSlide === 0 ? 'active' : ''}`}>
                  <img src="https://myorlandostay.com/img/Units/Unit1/Small/16_Small.JPG" className="d-block w-100" alt="about-photo 111" />
                </div>
                <div className={`carousel-item ${activeSlide === 1 ? 'active' : ''}`}>
                  <img src="https://myorlandostay.com/img/Units/Unit1/Small/16_Small.JPG" className="d-block w-100" alt="about-photo Ajay" />
                </div>
                <div className={`carousel-item ${activeSlide === 2 ? 'active' : ''}`}>
                  <img src="https://myorlandostay.com/img/Units/Unit1/Small/22_Small.JPG" className="d-block w-100" alt="about-photo" />
                </div>

                <div className={`carousel-item ${activeSlide === 3 ? 'active' : ''}`}>
                  <img src="https://myorlandostay.com/img/Units/Unit1/Small/22_Small.JPG" className="d-block w-100" alt="about-photo" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <img src="https://disneydreamrentals.com/img/units/unit1/slider_1.jpeg" className="img-responsive marbottomsm" alt="about-photo1" />
            <img src="https://myorlandostay.com/img/Units/Unit1/Small/18_Small.JPG" className="img-responsive marbottomsm" alt="about-photo2" />
          </div>
          <div className="col-sm-3 col-md-3">
            <img src="https://myorlandostay.com/img/Units/Unit1/Small/22_Small.JPG" className="img-responsive marbottomsm" alt="about-photo3" />
            <img src="https://myorlandostay.com/img/Units/Unit1/Small/28_Small.JPG" className="img-responsive marbottomsm" alt="about-photo4" />
            {/* <button className="btn btn-info photobtn" id="openBtn">
              <i className="fa fa-bars"></i> Show All Photos
            </button> */}

            <button onClick={togglePopup} className="btn btn-info photobtn"> <i className="fa fa-bars"></i> Show All Photos</button>
            {showPopup && <FullScreenPopup onClose={closePopup} />}
            <div className="overlay" id="overlay">
                 


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
};

export default ImageSlider;
