import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LodgixApiComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = "https://ykbaseapi.yugalkunj.org/ykrestapi/PaymentChannel/GetLodgixData?apiurl=%2Fpublic-api%2Fv2%2Fproperties%2F";

    

      try {
        const response = await axios.get(url);
        console.log('API Response:', response.data);

        // Assuming response.data has a 'results' field which is an array
        const results = response.data.results;
        setData(results);
      } catch (error) {
        console.error('API Fetch Error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!Array.isArray(data)) {
    console.log('Data is not an array:', data);
    return <div>Data is not an array: {JSON.stringify(data)}</div>;
  }

  return (
    <div>
      {/* <h1>Lodgix API Data</h1> */}
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <h2>{item.name}</h2>
            <p>Description: {item.description}</p>
            <p>Additional Info: {item.additional_info}</p>
            <p>Bathrooms: {item.bathrooms}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LodgixApiComponent;
