import React from 'react'

import { Link } from 'react-router-dom'
const CheckAvailability = () => {
 
    return (
      <>
      <div className="col-lg-4 col-md-12 col-xs-12">
        <div className="sidebar">
          <div className="sidebar-widget search-area-box-3 clearfix">
            <h3>Check Availability</h3>
            <div className="search-contents">
              <form >
                <div className="search-your-details">
                  <div className="row">
                    <div className="col-md-12 col-sm-6">
                      <div className="form-group">
                      <input type="date" className="btn-default datepicker" placeholder="Check In" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="form-group">
                        <input type="date" className="btn-default datepicker" placeholder="Check Out" />
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <select className="form-control search-fields" name="room">
                          <option>Room</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <select className="form-control search-fields" name="beds">
                          <option>Beds</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                      </div>
                    </div> */}
                                               <div className="col-md-6 col-sm-6">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="adults">
                                                          <option>Adult</option>
                                                          <option>1</option>
                                                          <option>2</option>
                                                          <option>3</option>
                                                          <option>4</option>
                                                          <option>5</option>
                                                      </select>
                                                  </div>
                                              </div> 
                                              <div className="col-md-6 col-sm-6">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="children">
                                                          <option>Child</option>
                                                          <option>1</option>
                                                          <option>2</option>
                                                          <option>3</option>
                                                          <option>4</option>
                                                          <option>5</option>
                                                      </select>
                                                  </div>
                                              </div>
  
                                              <div className="col-md-12 col-sm-12">
                                                  <div className="form-group">
                                                      <select className="form-control search-fields" name="Pet">
                                                          <option>Pet</option>
  
                                                          <option>Yes</option>
                                                          <option>No</option>
  
                                                      </select>
                                                  </div>
                                              </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group mb-0">
                        <Link type="submit" className="btn-md btn-theme btn-4 btn-7 w-100" to="/payment">Book Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="social-media sidebar-widget clearfix">
            <div className="main-title-2">
              <h1>Social Media</h1>
            </div>
            <ul className="social-list">
              <li><a href="#" className="facebook-bg"><i className="fa fa-facebook"></i></a></li>
              <li><a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a></li>
              <li><a href="#" className="rss-bg"><i className="fa fa-rss"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
        
      </>
    )
  }

export default CheckAvailability
