import React from 'react'

const Slider = () => {
  return (
    <>
       <div className="banner" id="banner1">
    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item item active">
                <img className="d-block w-100 h-100" src="https://myorlandostay.com/img/Units/Unit1/Small/Crestwynd%20Pool%20Small-2.JPG" alt="banner"/>
                <div className="carousel-caption banner-slider-inner d-flex h-100">
                    <div className="carousel-content container align-self-center">
                        <div className="text-center">
                            <div className="max-area2">
                                {/* <!-- <h1>Welcome To MyOrlando Stay</h1> -->
                                
                                <!-- <a className="btn-lg btn-2" href="#">Get Started Now</a>
                                <a className="btn-lg btn-1" href="#"><span>Learn More</span></a> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item item">
                <img className="d-block w-100 h-100" src="https://myorlandostay.com/img/Units/Unit1/Small/Crestwynd%20Pool%20Small-1.JPG" alt="banner"/>
                <div className="carousel-caption banner-slider-inner d-flex h-100">
                    <div className="carousel-content container align-self-center">
                        <div className="text-r">
                            <div className="max-area2">
                                {/* <!-- <h1>Best Place To Find Room</h1>
                                <p>Lorem ipsum dolor sit amet, conconsectetuer adipiscing elit Lorem ipsum dolor sit amet, conconsectetuer</p>
                                <a className="btn-lg btn-2" href="#">Get Started Now</a>
                                <a className="btn-lg btn-1" href="#"><span>Learn More</span></a> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item item">
                <img className="d-block w-100 h-100" src="https://myorlandostay.com/img/Units/Unit1/Small/01_Small.JPG" alt="banner"/>
                <div className="carousel-caption banner-slider-inner d-flex h-100">
                    <div className="carousel-content container align-self-center">
                        <div className="text-l">
                            <div className="max-area2">
                                {/* <!-- <h1>Best Place For Relux</h1>
                                <p>Lorem ipsum dolor sit amet, conconsectetuer adipiscing elit Lorem ipsum dolor sit amet, conconsectetuer</p>
                                <a className="btn-lg btn-2" href="#">Get Started Now</a>
                                <a className="btn-lg btn-1" href="#"><span>Learn More</span></a> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button> --> */}
    </div>
</div>
    </>
  )
}

export default Slider
