// PropertyTiles.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const PropertyTiles = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get('https://ykbaseapi.yugalkunj.org/ykrestapi/PaymentChannel/GetLodgixData?apiurl=%2Fpublic-api%2Fv2%2Fproperties%2F');
        const properties = response.data.results.map(property => ({
          id: property.id,
          name: property.name,
          description: property.description,
        }));
        setProperties(properties);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property details:', error);
        setError('An error occurred while fetching property details.');
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="content-area hotel-section bg-grey">
        <div className="overlay">
          <div className="container">
            <div className="main-title">
              <h1>Our Properties</h1>
            </div>
            <div className="row wow fadeInUp delay-04s" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
              {properties.map((property, index) => (
                <div key={property.id} className="col-lg-4 col-md-6 col-sm-12">
                  <div className="hotel-box">
                    <div className="detail clearfix">
                      <h3>
                        <Link to={`/Unit${index + 1}`}>{property.name}</Link>
                      </h3>
                      <p>{property.description}</p>
                      <p>
                        <Link className="btn-1 comon-btn bnt-none-992" to={`/Unit${index + 1}`}><span>Learn More</span></Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyTiles;
