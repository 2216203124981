import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
             {/* <!-- Footer start --> */}
<footer className="main-footer clearfix">
    <div className="container">
        {/* <!-- Footer info--> */}
        <div className="footer-info">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="footer-item">
                        <div className="main-title-2">
                            <h1>Contact Us</h1>
                        </div>
                        <ul className="personal-info">
                            <li>
                                <i className="fa fa-map-marker"></i>
                                Address: MyOrlandoStay at Crestwynd Bay Orlando,
                                Florida, FL 34747, The USA
                            </li>
                            <li>
                                <i className="fa fa-envelope"></i>
                                Email:<a href="mailto:crestwynd@earthlink.net">	
                                    
                                    crestwynd@earthlink.net</a>
                            </li>
                            <li>
                                <i className="fa fa-phone"></i>
                                Phone: <a href="tel:+1(407) 557-8999">+1(407) 557-8999</a>
                            </li>
                            <li>
                                <i className="fa fa-whatsapp"></i>
                               	Whatsapp: +91-9839048100
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-item">
                        <div className="main-title-2">
                            <h1>Usefull Links</h1>
                        </div>
                        <ul className="links">
                            <li>
                                <a href="#">Policies & Terms</a>
                            </li>
                         
                            <li>
                                <a href="#">Map & Directions</a>
                            </li>
                            <li>
                                <a href="#">Area Attractions</a>
                            </li>

                            <li>
                            <Link className="nav-link " to="/Properties1"  >
                               Proparties
                                </Link>
                               
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-item">
                        <div className="main-title-2">
                            <h1>Quick Links</h1>
                        </div>
                        <ul className="links">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#">About Us</a>
                            </li>
                            <li>
                                <a href="#">Blog</a>
                            </li>
                            {/* <!-- <li>
                                <a href="">Booking</a>
                            </li> -->
                            <!-- <li>
                                <a href="gallery-3column.html">Gallery</a>
                            </li> --> */}
                            <li>
                                <a href="#">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="footer-item newsletter">
                        <div className="main-title-2">
                            <a> <img src="https://myorlandostay.com/img/mylogo.png"/></a>
                        </div>
                        <div className="newsletter-inner">
                            <p>Situated in Orlando, Disney Area Vacation Rental by MyOrlandoStay offers multiple townhomes with a year-round outdoor heated pool. Disney's Hollywood Studios, Magic Kingdom, Animal Kingdom and Epcot are 1 to 1.5 miles away.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="copy-right">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <p> &copy;  2024 <a href="index.html" >MyOrlando Stay</a>. All Rights Reserved.</p>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="clearfix"></div>
                    <ul className="social-list">
                        <li><a href="https://twitter.com/i/flow/login?redirect_after_login=%2FMyOrlandoStay" className="twitter-bg"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.facebook.com/MyOrlandoStay" className="facebook-bg"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.pinterest.com/MyOrlandoStay/" className="rss-bg"><i className="fa fa-rss"></i></a></li>
                       
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
{/* <!-- Footer end --> */}
    </>
  )
}

export default Footer
