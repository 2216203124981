import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Amenities = () => {
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await axios.get('https://www.lodgix.com/public-api/v2/properties/62270/amenities/');
        setAmenities(response.data.results); 
        console.log(response)
      } catch (error) {
        console.error('Error fetching property amenities:', error);
        setError('Error fetching property amenities');
      } finally {
        setLoading(false);
      }
    };

    fetchAmenities();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <h1>Property Amenities</h1>
      <ul className="list-group">
        {amenities.map((amenity) => (
          <li className="list-group-item" key={amenity.id}>
            {amenity.name} {/* Adjust according to the API response structure */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Amenities;
