// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './asstes/css/bootstrap.css';
import './asstes/css/bootstrap-select.min.css';
import './asstes/css/bootstrap-submenu.css';
import './asstes/fonts/font-awesome/css/font-awesome.min.css';
import './asstes/fonts/flaticon/font/flaticon.css';
import './asstes/fonts/linearicons/style.css';
import './asstes/css/jquery.mCustomScrollbar.css';
import './asstes/css/bootstrap-datepicker.min.css';
import './asstes/css/dropzone.css';
import './asstes/css/slick.css';
import './asstes/css/leaflet.css';
import './asstes/css/initial.css';
import './asstes/css/style.css';
import './asstes/css/skins/default.css';




// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import Layout from './Components/Layout';
import About from './Components/About/About';
import Unit1Details from './Components/Units/Unit1';
import PaymentPage from './Components/Payment/Payment';
import Properties from './Components/Properties';
import Amenities from './Components/Units/Amenities';
import PropertyDetails from './Components/Units/PropertyDetails';
import Properties1 from './Components/Units/Properties1'
import App from './App';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './state/store'; // Import your store

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<App />} />
          <Route path='/About' element={<About />} />
          <Route path='/Unit1' element={<Unit1Details />} />
          <Route path='/payment' element={<PaymentPage />} />
          <Route path='/Properties' element={<Properties />} />
          <Route path='/Amenities' element={<Amenities />} />
          <Route path='/Properties1' element={<Properties1/>}/>
          <Route path="/property/:id" element={<PropertyDetails />} />
        </Route>
      </Routes>
    </Router>
  </Provider>
);
