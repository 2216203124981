import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPropertyDetails } from '../../Slice/propertySlice';
import Img_unit1 from '../../asstes/img/units/unit1/1.jpg';

const PropertyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const property = useSelector((state) => state.property.details);
  const loading = useSelector((state) => state.property.loading);
  const error = useSelector((state) => state.property.error);

  useEffect(() => {
    dispatch(fetchPropertyDetails(id));
  }, [dispatch, id]);

  if (loading) return <div>Loading...</div>;

  // Ensure error is rendered as a string
  if (error) return <div>{typeof error === 'object' ? JSON.stringify(error) : error}</div>;

  return (
    <div className="property-details">
      <h1>{property.name}</h1>
      <div className="property-images">
        {property.photos && property.photos.length > 0 ? (
          <img src={property.photos[0].url} alt="Property" />
        ) : (
          <img src={Img_unit1} alt="Default" />
        )}
      </div>
      <div className="property-info">
        <p><strong>Address:</strong> {property.address1}, {property.city}, {property.zip}</p>
        <p><strong>Bedrooms:</strong> {property.bedrooms}</p>
        <p><strong>Bathrooms:</strong> {property.bathrooms}</p>
        <p><strong>Max Guests:</strong> {property.max_guests}</p>
        <p><strong>Description:</strong> {property.description}</p>
        <p><strong>Additional Info:</strong> {property.additional_info}</p>
      </div>
    </div>
  );
};

export default PropertyDetails;
