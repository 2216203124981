import { Link } from 'react-router-dom';
import { useState } from 'react';

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <>
        {/* <!-- Top header start --> */}
        <header className="top-header" id="top-header-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-7 col-7">
                        <div className="list-inline">
                            <a href="tel:+55-4XX-634-7071"><i className="fa fa-phone"></i> +1(407) 557-8999</a>
                            <a href="tel:crestwynd@earthlink.net" className="d-none-768"><i className="fa fa-envelope"></i> crestwynd@earthlink.net </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-5 col-5">
                        <ul className="top-social-media pull-right">
                            <li>
                                <a href="#" className="sign-in"> <i className="fa fa-phone-square" aria-hidden="true"></i> Book Now </a>
                            </li>
                            <li>
                                <a href="#" className="sign-in"> <i className="fa fa-calendar"></i> Availability </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header> 
  
        <header className="main-header" id="main-header-1">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand logos" href="/">
                        <img src="https://myorlandostay.com/img/mylogo.png" alt="logo"/>
                    </Link>
                    {/* <button className="navbar-toggler" id="drawer" type="button">
                        <span className="fa fa-bars"></span>
                    </button> */}
                        <button className="navbar-toggler" type="button" onClick={toggleNav}>
                            <span className="fa fa-bars"></span>
                        </button>
  
  
                    <div className={`navbar-collapse collapse w-100 justify-content-end ${isNavOpen ? 'show' : ''}`} id="navbar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown active">
                                <Link className="nav-link " to="/" id="navbarDropdownMenuLink" >
                                     Home
                                </Link>
                            </li>
  
                            {/* <li className="nav-item dropdown ">
                                <Link className="nav-link " href="#" id="navbarDropdownMenuLink" >
                                    Experiences
                                </Link>
                            </li> */}
  
                            <li className="nav-item dropdown ">
                                <Link className="nav-link " to="/About" id="navbarDropdownMenuLink" >
                                    About Us 
                                </Link>
                            </li>
  
                            <li className="nav-item dropdown ">
                                <Link className="nav-link " href="/Contact" id="navbarDropdownMenuLink" >
                                    Contact US
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        {/* <!-- Main header end --> */}
  
        <nav id="sidebar" className="nav-sidebar sidebar-heading-section">
            <div id="dismiss">
                <i className="fa fa-close"></i>
            </div>
            <div className="sidebar-inner">
                <div className="sidebar-logo">
                    <img src="https://myorlandostay.com/img/mylogo.png" alt="sidebarlogo"/>
                </div>
                <div className="sidebar-navigation">
                    <ul className="menu-list">
                        <li>
                            <Link href="#" className="active pt0">index </Link>
                        </li>
                        <li>
                            <Link href="#" className="active pt0">About Us </Link>
                        </li>
                        <li><Link href="#">Contact </Link></li>
                        <li><Link href="#">Blog</Link></li>
                    </ul>
                </div>
                <div className="get-in-touch">
                    <h3 className="heading">Get in Touch</h3>
                    <div className="sidebar-contact-info">
                        <div className="icon">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="body-info">
                            <a href="tel:+1(407) 557-8999">+1(407) 557-8999</a>
                        </div>
                        <div className="body-info">
                            <a href="tel:+91-9839048100">	+91-9839048100</a>
                        </div>
                    </div>
                    <div className="sidebar-contact-info">
                        <div className="icon">
                            <i className="fa fa-envelope"></i>
                        </div>
                        <div className="body-info">
                            <a href="mailto:crestwynd@earthlink.net">crestwynd@earthlink.net</a>
                        </div>
                    </div>
                </div>
                <div className="get-social">
                    <h3 className="heading">Get Social</h3>
                    <a href="#" className="facebook-bg">
                        <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#" className="twitter-bg">
                        <i className="fa fa-twitter"></i>
                    </a>
                    <a href="#" className="linkedin-bg">
                        <i className="fa fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </nav>
      </>
    );
};

export default Header;
